export const burnOutset1 = [
  {
    question: "I feel emotionally drained after work",
    answer: "",
  },
  {
    question: "I feel used up by the end of the workday",
    answer: "",
  },
  {
    question:
      "I feel tired when I get up in the morning and have to face another day at work",
    answer: "",
  },
  {
    question: "Working all day is really a strain for me",
    answer: "",
  },
  {
    question: "I feel burned out from my work",
    answer: "",
  },
  {
    question: "I feel frustrated by my job",
    answer: "",
  },
  {
    question: "I feel I'm working too hard on my job",
    answer: "",
  },
  {
    question: "Working directly with people puts too much stress on me",
    answer: "",
  },
  {
    question: "I feel like I'm at my wit's end",
    answer: "",
  },
];

export const burnOutset2 = [
  {
    question:
      "I feel I treat some people at work as if they were impersonal objects",
    answer: "",
  },
  {
    question: "I've become more callous toward people since I took this job",
    answer: "",
  },
  {
    question: "I worry that this job is hardening me emotionally",
    answer: "",
  },
  {
    question: "I don't really care what happens to some people at work",
    answer: "",
  },
  {
    question: "I feel people blame me for some of their problems",
    answer: "",
  },
];

export const burnOutset3 = [
  {
    question: "I can easily understand how people at work feel",
    answer: "",
  },
  {
    question: "I deal very effectively with work problems",
    answer: "",
  },
  {
    question:
      "I feel I'm positively influencing other people's lives through my work",
    answer: "",
  },
  {
    question: "I feel very energetic",
    answer: "",
  },
  {
    question: "I can easily create a chill atmosphere with people at work",
    answer: "",
  },
  {
    question: "I feel good after working closely with others",
    answer: "",
  },
  {
    question: "I have accomplished many worthwhile things in this job",
    answer: "",
  },
  {
    question: "In my work, I deal with emotional problems very calmly",
    answer: "",
  },
];

export const burnOutOptions = [
  { value: "Never", selected: false, scale: 0 },
  { value: "A few times a year", selected: false, scale: 1 },
  { value: "Once a month", selected: false, scale: 2 },
  { value: "A few times a month", selected: false, scale: 3 },
  { value: "Once a week", selected: false, scale: 4 },
  { value: "A few times a week", selected: false, scale: 5 },
  { value: "Every day", selected: false, scale: 6 },
];

export const stressQuestions = [
  {
    question:
      "How often have you been upset because something unexpected happened?",
    answer: "",
  },
  {
    question:
      "How often have you felt unable to control the important things in your life?",
    answer: "",
  },
  {
    question: "How often have you felt nervous and stressed?",
    answer: "",
  },
  {
    question:
      "How often have you felt confident about handling your personal problems? (Reverse scored)",
    answer: "",
  },
  {
    question:
      "How often have you felt things were going your way? (Reverse scored)",
    answer: "",
  },
  {
    question:
      "How often have you found you couldn't cope with all the things you had to do?",
    answer: "",
  },
  {
    question:
      "How often have you been able to control irritations in your life? (Reverse scored)",
    answer: "",
  },
  {
    question: "How often have you felt on top of things? (Reverse scored)",
    answer: "",
  },
  {
    question:
      "How often have you been angered because of things outside your control?",
    answer: "",
  },
  {
    question:
      "How often have you felt difficulties were piling up so high that you couldn't overcome them?",
    answer: "",
  },
];

export const stressOptions = [
  { value: "Never", selected: false, scale: 0 },
  { value: "Almost Never", selected: false, scale: 1 },
  { value: "Sometimes", selected: false, scale: 2 },
  { value: "Fairly Often", selected: false, scale: 3 },
  { value: "Very Often", selected: false, scale: 4 },
];
export const stressOptions2 = [
  { value: "Never", selected: false, scale: 4},
  { value: "Almost Never", selected: false, scale: 3 },
  { value: "Sometimes", selected: false, scale: 2 },
  { value: "Fairly Often", selected: false, scale: 1 },
  { value: "Very Often", selected: false, scale: 0 },
];
export const eqQuestions = [
  {
    question: "I know when to speak about my personal problems to others",
    answer: "",
  },
  {
    question:
      "When I face challenges, I remember times I faced similar challenges and succeeded",
    answer: "",
  },
  {
    question: "I expect good things to happen",
    answer: "",
  },
  {
    question: "Other people find it easy to confide in me",
    answer: "",
  },
  {
    question:
      "I find it hard to understand the non-verbal messages of other people",
    answer: "",
  },
  {
    question:
      "Some of the major events of my life have led me to re-evaluate what is important and not important",
    answer: "",
  },
  {
    question: "When my mood changes, I see new possibilities",
    answer: "",
  },
  {
    question: "Emotions are one of the things that make my life worth living",
    answer: "",
  },
  {
    question: "I am aware of my emotions as I experience them",
    answer: "",
  },
  {
    question: "I expect good things to happen",
    answer: "",
  },
  {
    question: "I like to share my emotions with others",
    answer: "",
  },
  {
    question:
      "When I experience a positive emotion, I know how to make it last",
    answer: "",
  },
  {
    question: "I arrange events others enjoy",
    answer: "",
  },
  {
    question: "I seek out activities that make me happy",
    answer: "",
  },
  {
    question: "I am aware of the non-verbal messages I send to others",
    answer: "",
  },
  {
    question:
      "I present myself in a way that makes a good impression on others",
    answer: "",
  },
  {
    question: "When I am in a positive mood, solving problems is easy for me",
    answer: "",
  },
  {
    question:
      "By looking at their facial expressions, I recognize the emotions people are experiencing",
    answer: "",
  },
  {
    question: "I know why my emotions change",
    answer: "",
  },
  {
    question:
      "When I am in a positive mood, I am able to come up with new ideas",
    answer: "",
  },
  {
    question: "I have control over my emotions",
    answer: "",
  },
  {
    question: "I easily recognize my emotions as I experience them",
    answer: "",
  },
  {
    question:
      "I motivate myself by imagining a good outcome to tasks I take on",
    answer: "",
  },
  {
    question: "I compliment others when they have done something well",
    answer: "",
  },
  {
    question: "I am aware of the non-verbal messages other people send",
    answer: "",
  },
  {
    question:
      "When another person tells me about an important event in their life, I almost feel as though I experienced this event myself",
    answer: "",
  },
  {
    question:
      "When I feel a change in emotions, I tend to come up with new ideas",
    answer: "",
  },
  {
    question:
      "When I am faced with a challenge, I give up because I believe I will fail",
    answer: "",
  },
  {
    question: "I know what other people are feeling just by looking at them",
    answer: "",
  },
  {
    question: "I help other people feel better when they are down",
    answer: "",
  },
  {
    question:
      "I use good moods to help myself keep trying in the face of obstacles",
    answer: "",
  },
  {
    question:
      "I can tell how people are feeling by listening to the tone of their voice",
    answer: "",
  },
  {
    question:
      "It is difficult for me to understand why people feel the way they feel",
    answer: "",
  },
];

export const eqOptions = [
  { value: "Not me at all", selected: false, scale: 1 },
  { value: "Usually not me", selected: false, scale: 2 },
  { value: "Sometimes me", selected: false, scale: 3 },
  { value: "Usually me", selected: false, scale: 4 },
  { value: "Totally me", selected: false, scale: 5 },
];
export const eqOptions2 = [
  { value: "Not me at all", selected: false, scale: 5 },
  { value: "Usually not me", selected: false, scale: 4 },
  { value: "Sometimes me", selected: false, scale: 3 },
  { value: "Usually me", selected: false, scale: 2 },
  { value: "Totally me", selected: false, scale: 1 },
];
export const workLifeQuestions = [
  {
    question: "I feel I am being paid a fair amount for the work I do",
    answer: "",
  },
  {
    question: "Raises are too few and far between ",
    answer: "",
  },
  {
    question: "I am satisfied with my chances for salary increases",
    answer: "",
  },
  {
    question: "The benefits package we have is equitable",
    answer: "",
  },
  {
    question:
      "The benefits we receive are as good as most other organizations offer",
    answer: "",
  },
  {
    question: "There are benefits we do not have which we should have ",
    answer: "",
  },
  {
    question: "There is really too little chance for promotion on my job ",
    answer: "",
  },
  {
    question:
      "Those who do well on the job stand a fair chance of being promoted",
    answer: "",
  },
  {
    question: "People get ahead as fast here as they do in other places",
    answer: "",
  },
  {
    question: "I am satisfied with my chances for promotion",
    answer: "",
  },
  {
    question: "My supervisor is pretty competent in doing their job",
    answer: "",
  },
  {
    question: "My supervisor is unfair to me ",
    answer: "",
  },
  {
    question:
      "My supervisor shows too little interest in the feelings of subordinates ",
    answer: "",
  },
  {
    question: "I like my supervisor",
    answer: "",
  },
  {
    question: "I like doing the things I do at work",
    answer: "",
  },
  {
    question: "I sometimes feel my job is meaningless ",
    answer: "",
  },
  {
    question: "My job is enjoyable",
    answer: "",
  },
  {
    question: "I feel a sense of pride in doing my job",
    answer: "",
  },
  {
    question: "Communications seem good within this organization",
    answer: "",
  },
  {
    question: "The goals of this organization are not clear to me ",
    answer: "",
  },
  {
    question: "I like the people I work with",
    answer: "",
  },
  {
    question:
      "I find I have to work harder at my job than I should because of the incompetence of people I work with ",
    answer: "",
  },
  {
    question: "I enjoy my coworkers",
    answer: "",
  },
  {
    question: "I don't feel my efforts are rewarded the way they should be ",
    answer: "",
  },
  {
    question:
      "Many of our rules and procedures make doing a good job difficult ",
    answer: "",
  },
  {
    question: "My efforts to do a good job are seldom blocked by red tape",
    answer: "",
  },
];

export const workLifeOptions = [
  { value: "Strongly Disagree", selected: false, scale: 1 },
  { value: "Disagree", selected: false, scale: 2 },
  { value: "Slightly Disagree", selected: false, scale: 3 },
  { value: "Slightly Agree", selected: false, scale: 4 },
  { value: "Agree", selected: false, scale: 5 },
  { value: "Strongly Agree", selected: false, scale: 6 },
];
export const workLifeOptions2 = [
  { value: "Strongly Disagree", selected: false, scale: 6 },
  { value: "Disagree", selected: false, scale: 5 },
  { value: "Slightly Disagree", selected: false, scale: 4 },
  { value: "Slightly Agree", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 2 },
  { value: "Strongly Agree", selected: false, scale: 1 },
];
export const resilienceQuestions = [
  {
    question: "I adapt to change easily",
    answer: "",
  },
  {
    question: "I can deal with whatever comes my way",
    answer: "",
  },
  {
    question: "I try to see the humorous side of problems",
    answer: "",
  },
  {
    question: "Coping with stress can make me stronger",
    answer: "",
  },
  {
    question: "I tend to bounce back after illness or hardship",
    answer: "",
  },
  {
    question: "I can achieve my goals despite obstacles",
    answer: "",
  },
  {
    question: "I can stay focused under pressure",
    answer: "",
  },
  {
    question: "I am not easily discouraged by failure",
    answer: "",
  },
  {
    question: "I think of myself as a strong person",
    answer: "",
  },
  {
    question: "I can handle unpleasant feelings",
    answer: "",
  },
  {
    question: "I have to act on a hunch sometimes",
    answer: "",
  },
  {
    question: "Under pressure, I stay focused and think clearly",
    answer: "",
  },
  {
    question: "I prefer to take the lead in problem-solving",
    answer: "",
  },
  {
    question: "I can make unpopular or difficult decisions",
    answer: "",
  },
  {
    question: "I can handle failure",
    answer: "",
  },
  {
    question: "I have strong sense of purpose",
    answer: "",
  },
  {
    question: "Past successes give me confidence",
    answer: "",
  },
  {
    question: "I give my best effort no matter what",
    answer: "",
  },
  {
    question: "I work to attain my goals",
    answer: "",
  },
  {
    question: "I have close and secure relationships",
    answer: "",
  },
  {
    question: "Sometimes fate or God can help",
    answer: "",
  },
  {
    question: "Things happen for a reason",
    answer: "",
  },
  {
    question: "I like challenges",
    answer: "",
  },
  {
    question: "I take pride in my achievements",
    answer: "",
  },
  {
    question: "I have meaningful relationships",
    answer: "",
  },
];

export const resilienceOptions = [
  { value: "Not true at all", selected: false, scale: 0 },
  { value: "Rarely true", selected: false, scale: 1 },
  { value: "Sometimes true", selected: false, scale: 2 },
  { value: "Often true", selected: false, scale: 3 },
  { value: "True nearly all the time", selected: false, scale: 4 },
];

export const lifeQuestions = [
  {
    question: "My personal life suffers because of work",
    answer: "",
  },
  {
    question: "My job makes it difficult to maintain personal relationships",
    answer: "",
  },
  {
    question: "I neglect personal needs because of work",
    answer: "",
  },
  {
    question: "I put my personal life on hold for work",
    answer: "",
  },
  {
    question: "I miss personal activities because of work",
    answer: "",
  },
  {
    question: "I struggle to juggle work and non-work activities",
    answer: "",
  },
  {
    question: "I'm too tired to be effective at work",
    answer: "",
  },
  {
    question: "My personal life drains me of energy for work",
    answer: "",
  },
  {
    question:
      "I'm too tired to be effective at work because of personal activities",
    answer: "",
  },
  {
    question: "My work suffers because of my personal life",
    answer: "",
  },
  {
    question: "It's hard to work because of personal matters",
    answer: "",
  },
  {
    question: "My personal life gives me energy for my job",
    answer: "",
  },
  {
    question: "I'm in a better mood at work because of my personal life",
    answer: "",
  },
  {
    question: "My personal life helps me relax and feel ready for work",
    answer: "",
  },
  {
    question: "I have more energy to do personal activities because of my job",
    answer: "",
  },
  {
    question: "I'm in a better mood for my personal life because of my job",
    answer: "",
  },
  {
    question: "My job gives me energy for my personal activities",
    answer: "",
  },
  {
    question: "I'm better at managing time at work because of my personal life",
    answer: "",
  },
  {
    question:
      "I'm better at managing time in my personal life because of my job",
    answer: "",
  },
];

export const lifeOptions = [
  { value: "Not at all", selected: false, scale: 1 },
  { value: "Sometimes", selected: false, scale: 2 },
  { value: "Often", selected: false, scale: 3 },
  { value: "Almost always", selected: false, scale: 4 },
  { value: "Always", selected: false, scale: 5 },
];

export const teamQuestions = [
  {
    question: "Our team members understand each other's moods",
    answer: "",
  },
  {
    question: "We can tell when someone in the team is upset",
    answer: "",
  },
  {
    question: "Team members are aware of each other's strengths",
    answer: "",
  },
  {
    question: "We notice when someone is struggling",
    answer: "",
  },
  {
    question: "Our team picks up on emotional undercurrents",
    answer: "",
  },
  {
    question: "We acknowledge each other's feelings openly",
    answer: "",
  },
  {
    question: "Our team stays cool under pressure",
    answer: "",
  },
  {
    question: "We handle conflicts constructively",
    answer: "",
  },
  {
    question: "The team bounces back quickly from setbacks",
    answer: "",
  },
  {
    question: "We adjust our approach based on team mood",
    answer: "",
  },
  {
    question: "Team members help each other stay motivated",
    answer: "",
  },
  {
    question: "We maintain positivity during challenges",
    answer: "",
  },
  {
    question: "Team members express their feelings appropriately",
    answer: "",
  },
  {
    question: "We communicate emotions clearly",
    answer: "",
  },
  {
    question: "The team discusses emotional impacts of decisions",
    answer: "",
  },
  {
    question: "We give emotional support to each other",
    answer: "",
  },
  {
    question: "Team members feel safe sharing concerns",
    answer: "",
  },
  {
    question: "We celebrate each other's successes",
    answer: "",
  },
  {
    question: "Our team builds strong relationships",
    answer: "",
  },
  {
    question: "We show empathy towards each other",
    answer: "",
  },
  {
    question: "The team handles disagreements respectfully",
    answer: "",
  },
  {
    question: "We listen to each other actively",
    answer: "",
  },
  {
    question: "Team members include everyone in discussions",
    answer: "",
  },
  {
    question: "We provide constructive feedback kindly",
    answer: "",
  },
];

export const teamOptions = [
  { value: "Never", selected: false, scale: 1 },
  { value: "Rarely", selected: false, scale: 2 },
  { value: "Sometimes", selected: false, scale: 3 },
  { value: "Often", selected: false, scale: 4 },
  { value: "Always", selected: false, scale: 5 },
];

export const roleQuestions = [
  {
    question: "I know exactly what is expected of me",
    answer: "",
  },
  {
    question: "I have clear, planned goals for my job",
    answer: "",
  },
  {
    question: "I know my responsibilities",
    answer: "",
  },
  {
    question: "I know how to divide my time properly",
    answer: "",
  },
  {
    question: "I know what my authority is",
    answer: "",
  },
  {
    question: "The explanation of what needs to be done is clear",
    answer: "",
  },
  {
    question: "I work with two or more groups who operate quite differently",
    answer: "",
  },
  {
    question: "I receive incompatible requests from two or more people",
    answer: "",
  },
  {
    question:
      "I do things that are apt to be accepted by one person and not accepted by others",
    answer: "",
  },
  {
    question:
      "I receive assignments without adequate resources to complete them",
    answer: "",
  },
  {
    question: "I work on unnecessary things",
    answer: "",
  },
  {
    question: "I have to work under vague directives or orders",
    answer: "",
  },
  {
    question:
      "I have to bend or break a rule or policy to carry out an assignment",
    answer: "",
  },
  {
    question:
      "I receive assignments without the proper staffing to complete them",
    answer: "",
  },
];

export const roleOptions = [
  { value: "Very False", selected: false, scale: 1 },
  { value: "False", selected: false, scale: 2 },
  { value: "Somewhat False", selected: false, scale: 3 },
  { value: "Neutral", selected: false, scale: 4 },
  { value: "Somewhat True", selected: false, scale: 5 },
  { value: "True", selected: false, scale: 6 },
  { value: "Very True", selected: false, scale: 7 },
];

export const supportQuestions = [
  {
    question: "The organization values my contribution to its well-being",
    answer: "",
  },
  {
    question:
      "The organization fails to appreciate any extra effort from me ",
    answer: "",
  },
  {
    question: "The organization would ignore any complaint from me ",
    answer: "",
  },
  {
    question: "The organization really cares about my well-being",
    answer: "",
  },
  {
    question:
      "Even if I did the best job possible, the organization would fail to notice ",
    answer: "",
  },
  {
    question: "The organization cares about my general satisfaction at work",
    answer: "",
  },
  {
    question: "The organization shows very little concern for me ",
    answer: "",
  },
  {
    question: "The organization takes pride in my accomplishments at work",
    answer: "",
  },
  {
    question:
      "The organization tries to make my job as interesting as possible",
    answer: "",
  },
  {
    question:
      "The organization is willing to extend itself to help me perform my job",
    answer: "",
  },
  {
    question: "Help is available from the organization when I have a problem",
    answer: "",
  },
  {
    question: "The organization would forgive an honest mistake on my part",
    answer: "",
  },
  {
    question:
      "If given the opportunity, the organization would take advantage of me ",
    answer: "",
  },
  {
    question: "The organization provides me opportunities to grow",
    answer: "",
  },
  {
    question: "The organization considers my goals and values",
    answer: "",
  },
  {
    question: "The organization provides me with tools for success",
    answer: "",
  },
];

export const supportOptions = [
  { value: "Strongly Disagree", selected: false, scale: 0 },
  { value: "Moderately Disagree", selected: false, scale: 1 },
  { value: "Slightly Disagree", selected: false, scale: 2 },
  { value: "Neither Agree nor Disagree", selected: false, scale: 3 },
  { value: "Slightly Agree", selected: false, scale: 4 },
  { value: "Moderately Agree", selected: false, scale: 5 },
  { value: "Strongly Agree", selected: false, scale: 6 },
];

export const personalityQuestions = [
  {
    question: "I enjoy trying new things and experiences",
    answer: "",
  },
  {
    question: "I'm curious about many different things",
    answer: "",
  },
  {
    question: "I value artistic or creative activities",
    answer: "",
  },
  {
    question: "I prefer routine over variety ",
    answer: "",
  },
  {
    question: "I enjoy abstract or theoretical discussions",
    answer: "",
  },
  {
    question: "I like to learn about different cultures and ideas",
    answer: "",
  },
  {
    question: "I am organized and follow a schedule",
    answer: "",
  },
  {
    question: "I pay attention to details",
    answer: "",
  },
  {
    question: "I complete tasks thoroughly",
    answer: "",
  },
  {
    question: "I prefer spontaneity over planning ",
    answer: "",
  },
  {
    question: "I set clear goals and work toward them",
    answer: "",
  },
  {
    question: "I think carefully before acting",
    answer: "",
  },
  {
    question: "I enjoy being the center of attention",
    answer: "",
  },
  {
    question: "I feel energized around others",
    answer: "",
  },
  {
    question: "I start conversations with new people easily",
    answer: "",
  },
  {
    question: "I prefer quiet, solitary activities ",
    answer: "",
  },
  {
    question: "I express my emotions openly",
    answer: "",
  },
  {
    question: "I seek out exciting experiences",
    answer: "",
  },
  {
    question: "I care about others' feelings",
    answer: "",
  },
  {
    question: "I enjoy helping others",
    answer: "",
  },
  {
    question: "I try to avoid conflicts",
    answer: "",
  },
  {
    question: "I can be critical of others ",
    answer: "",
  },
  {
    question: "I give people the benefit of the doubt",
    answer: "",
  },
  {
    question: "I cooperate well with others",
    answer: "",
  },
  {
    question: "I worry about things often",
    answer: "",
  },
  {
    question: "I get stressed easily",
    answer: "",
  },
  {
    question: "I have frequent mood changes",
    answer: "",
  },
  {
    question: "I stay calm under pressure ",
    answer: "",
  },
  {
    question: "I get upset easily",
    answer: "",
  },
  {
    question: "I experience anxiety in social situations",
    answer: "",
  },
];

export const personalityOptions = [
  { value: "Strongly Disagree", selected: false, scale: 1 },
  { value: "Disagree", selected: false, scale: 2 },
  { value: "Neutral", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 4 },
  { value: "Strongly Agree", selected: false, scale: 5 },
];

export const personalityOptions2 = [
  { value: "Strongly Disagree", selected: false, scale: 5 },
  { value: "Disagree", selected: false, scale: 4 },
  { value: "Neutral", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 2 },
  { value: "Strongly Agree", selected: false, scale: 1 },
];

export const safeQuestions = [
  {
    question:
      "If you make a mistake on this team, it is often held against you ",
    answer: "",
  },
  {
    question:
      "Members of this team are able to bring up problems and tough issues",
    answer: "",
  },
  {
    question:
      "People on this team sometimes reject others for being different ",
    answer: "",
  },
  {
    question: "It is safe to take a risk on this team",
    answer: "",
  },
  {
    question: "It is difficult to ask other members of this team for help ",
    answer: "",
  },
  {
    question:
      "No one on this team would deliberately act in a way that undermines my efforts",
    answer: "",
  },
  {
    question: "My unique skills and talents are valued and utilized",
    answer: "",
  },
  {
    question:
      "Working with members of this team, my abilities and contributions are recognized",
    answer: "",
  },
  {
    question:
      "Members of this team are able to discuss mistakes and learn from them",
    answer: "",
  },
  {
    question: "It is easy to discuss difficult issues and problems",
    answer: "",
  },
  {
    question: "The team respects and values diverse perspectives",
    answer: "",
  },
  {
    question: "The atmosphere supports learning and experimentation",
    answer: "",
  },
];

export const safeOptions = [
  { value: "Strongly Disagree", selected: false, scale: 1 },
  { value: "Disagree", selected: false, scale: 2 },
  { value: "Neither Agree nor Disagree", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 4 },
  { value: "Strongly Agree", selected: false, scale: 5 },
];
export const safeOptions2 = [
  { value: "Strongly Disagree", selected: false, scale: 5 },
  { value: "Disagree", selected: false, scale: 4 },
  { value: "Neither Agree nor Disagree", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 2 },
  { value: "Strongly Agree", selected: false, scale: 1},
];

export const digitalQuestions = [
  {
    question: "I am forced to work much faster due to technology",
    answer: "",
  },
  {
    question: "I am forced to do more work than I can handle due to technology",
    answer: "",
  },
  {
    question:
      "I am forced to work with very tight time schedules due to technology",
    answer: "",
  },
  {
    question:
      "I am forced to change my work habits to adapt to new technologies",
    answer: "",
  },
  {
    question:
      "I have a higher workload because of increased technology complexity",
    answer: "",
  },
  {
    question: "I spend less time with my family due to technology",
    answer: "",
  },
  {
    question:
      "I have to be in touch with my work even during my vacation due to technology",
    answer: "",
  },
  {
    question:
      "I have to sacrifice my vacation and weekend time to keep up with technology",
    answer: "",
  },
  {
    question: "I feel my personal life is being invaded by technology",
    answer: "",
  },
  {
    question: "Technology creates conflicts between my work and personal life",
    answer: "",
  },
  {
    question: "Our organization provides training for new technology",
    answer: "",
  },
  {
    question: "Our organization has a help desk for technology issues",
    answer: "",
  },
  {
    question: "Our organization encourages knowledge sharing about technology",
    answer: "",
  },
  {
    question: "Technical support is available when needed",
    answer: "",
  },
  {
    question: "Resources are allocated for technology adoption",
    answer: "",
  },
];

export const digitalOptions = [
  { value: "Strongly Disagree", selected: false, scale: 1 },
  { value: "Disagree", selected: false, scale: 2 },
  { value: "Neutral", selected: false, scale: 3 },
  { value: "Agree", selected: false, scale: 4 },
  { value: "Strongly Agree", selected: false, scale: 5 },
];
