import React from "react";

const WelcomeScreen = () => {
  return (
    <div className="inputcontainer_ col-md-12 mt-md-5 mt-3 ml-lg-2 px-1" >
      <p className="toptext_ mt-md-2 pt-md-1 font-weight-bold h4 h2-md">
        Welcome to Careme Health's Well-Being Assessment
      </p>
      <p className="secondtxt_ lead mt-3">
        We're excited to help you on your journey towards better mental health
        😊. This well-being assessment will evaluate six crucial areas of your
        life{" "}
      </p>
      <div className="checkbox_">
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🔥 Stress and Burnout Levels
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🤝 Emotional Intelligence (EI)
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          😊 Workplace Satisfaction and Engagement
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🛡️ Coping Mechanisms and Resilience
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          ⚖️ Work-Life Balance
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          👥 Interpersonal Dynamics and Team Compatibility
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          📝 Role Clarity and Fit
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🤲 Perceived Organizational Support
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          📊 Personality and Behavioral Traits
        </button>{" "}
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          💬 Psychological Safety
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🌍 Diversity, Equity, and Inclusion (DEI) Perception
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          💻 Digital and Technological Stress
        </button>
      </div>
      <p className="btmtop_ secondtxt_ lead mt-3">
        By completing this assessment, you'll gain insights into your strengths
        and areas for improvement. Based on your responses, we'll provide
        personalized feedback and suggestions to help you achieve a balanced,
        healthy lifestyle 💪.{" "}
      </p>
    </div>
  );
};

export default WelcomeScreen;
