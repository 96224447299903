import React, { useEffect, useRef, useState } from "react";
import Caremeloader from "../Caremeloader";
import FullScreenConfetti from "../Confetti";
import LeftContent from "./LeftContent";
import QuestionPagination from "./QuestionPagination";
import {
  burnOutOptions,
  burnOutset1,
  burnOutset2,
  burnOutset3,
  digitalOptions,
  digitalQuestions,
  eqOptions,
  eqOptions2,
  eqQuestions,
  lifeOptions,
  lifeQuestions,
  personalityOptions,
  personalityOptions2,
  personalityQuestions,
  resilienceOptions,
  resilienceQuestions,
  roleOptions,
  roleQuestions,
  safeOptions,
  safeOptions2,
  safeQuestions,
  stressOptions,
  stressOptions2,
  stressQuestions,
  supportOptions,
  supportQuestions,
  teamOptions,
  teamQuestions,
  workLifeOptions,
  workLifeOptions2,
  workLifeQuestions,
} from "./Questions";
import ResultComponent from "./ResultComponent";
import ResultModal from "./ResultModal";
import WelcomeScreen from "./WelcomeScreen";
import { ArrowRight, Check } from "react-feather";
import { FaAngleDoubleRight } from "react-icons/fa";
import { BsSkipForward } from "react-icons/bs";
import { patchReq, postReq } from "../../../utils/apicall";
import { Button, Modal } from "react-bootstrap";
import { APP_URL, LOGIN_URL } from "../../processENV";
import { APP_BASEURL } from "../../../agora.config";

const WellbeingHome = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showcofetti, setShowConfetti] = useState(false);
  const [showresultmodal, setShowResultModal] = useState(false);
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [showresult, setShowResult] = useState(false);
  const [prevResponse, setPrevResponse]=useState({});
  const [extraQuestions, setExtraQuestions] = useState({
    burnOut1: [
      {
        question: "When you feel most drained, what helps you recharge?",
        answer: "",
      },
    ],
    burnOut2: [
      {
        question: "Can you recall moments where you felt disconnected at work?",
        answer: "",
      },
      {
        question1:
          "Can you recall moments where you felt disconnected at work?",
        answer1: "",
      },
    ],
    burnOut3: [
      {
        question:
          "What achievements at work have made you feel the most fulfilled?",
        answer: "",
      },
      { question1: "Why do you think they stood out?", answer1: "" },
    ],
    stressAssessment: [
      {
        question: `When stress hits hard, what's your go-to way to deal?`,
        answer: "",
      },
      {
        question1: `What's the biggest source of stress in your life right now? `,
        answer1: "",
      },
    ],
    eqAssessment: [
      {
        question: `Think about a recent emotional challenge at work. How did you handle it, and what did you learn about yourself?`,
        answer: "",
      },
      {
        question1: `What's your biggest emotional strength, and how does it show up in your work life?`,
        answer1: "",
      },
    ],
    workAssessment: [
      {
        question: `What's one thing that would make your work life significantly better right now?`,
        answer: "",
      },
      {
        question1: `Think about your best day at work recently - what made it great?`,
        answer1: "",
      },
    ],
    lifeAssessment: [
      {
        question: `Think about your toughest challenge recently - what helped you get through it?`,
        answer: "",
      },
      {
        question1: `What's your go-to source of strength when things get rough?`,
        answer1: "",
      },
    ],
    teamAssessment: [
      {
        question: `What's your ideal "perfect day" balancing work and personal life?`,
        answer: "",
      },
      {
        question1: `What's the biggest obstacle to achieving better work-life balance right now?`,
        answer1: "",
      },
    ],
    roleAssessment: [
      {
        question: `Think about your team's best moment recently - what made it special?`,
        answer: "",
      },
      {
        question1: `If you could improve one thing about how your team works together, what would it be?`,
        answer1: "",
      },
    ],
    resilianceAssessment: [
      {
        question: `Think about a time when your role felt really clear and satisfying - what made it work?`,
        answer: "",
      },
      {
        question1: `When you face conflicting demands, how do you typically handle them?`,
        answer1: "",
      },
    ],
    supportAssessment: [
      {
        question: `What's one thing your organization does that makes you feel most supported?`,
        answer: "",
      },
      {
        question1: `If you could improve one aspect of organizational support, what would it be and why?`,
        answer1: "",
      },
    ],
    personalityAssessment: [
      {
        question: `Think about when you feel most "in your element" - what kind of situation is it? `,
        answer: "",
      },
      {
        question1: `How do you think your personality traits show up in your work style?`,
        answer1: "",
      },
    ],
    safeAssessment: [
      {
        question: `Think about a time when you felt completely safe to be yourself at work - what made that possible? `,
        answer: "",
      },
      {
        question1: `What would make you feel more comfortable speaking up in your team?`,
        answer1: "",
      },
    ],
    digitalAssessment: [
      {
        question: `What's your favorite way to unplug and recharge from tech?`,
        answer: "",
      },
      {
        question1: `If you could change one thing about how technology affects your work life, what would it be?`,
        answer1: "",
      },
    ],
  });
  const [wellbeingId, setWellbeingId]=useState(0);
  const [burnOut1, setBurnOut1] = useState(
    burnOutset1.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: burnOutOptions,
    }))
  );
  const [burnOut2, setBurnOut2] = useState(
    burnOutset2.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: burnOutOptions,
    }))
  );
  const [burnOut3, setBurnOut3] = useState(
    burnOutset3.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: burnOutOptions,
    }))
  );
  const [indx1, setIndx1] = useState(0);
  const [indx2, setIndx2] = useState(0);
  const [indx3, setIndx3] = useState(0);
  const [stressAssessment, setStressAssessment] = useState(
    stressQuestions.map((item, index) => ({
      ...item,
      scale: 0,
      answeOptions:
        index === 3 || index === 4 || index === 6 || index === 7
          ? stressOptions2
          : stressOptions,
    }))
  );
  const [stressIndex, setStressIndex] = useState(0);
  const [eqAssessment, setEqAssessment] = useState(
    eqQuestions.map((item, index) => ({
      ...item,
      scale: 0,
      answeOptions:
        index === 4 || index === 27 || index === 32 ? eqOptions2 : eqOptions,
    }))
  );
  const [eqIndex, setEqIndex] = useState(0);
  const [workAssessment, setWorkAssessment] = useState(
    workLifeQuestions.map((item, index) => ({
      ...item,
      scale: 0,
      answeOptions: [1, 5, 6, 11, 12, 15, 19, 21, 23, 24].includes(index)
        ? workLifeOptions2
        : workLifeOptions,
    }))
  );
  const [workIndex, setWorkIndex] = useState(0);
  const [lifeAssessment, setLifeAssessment] = useState(
    lifeQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: lifeOptions,
    }))
  );
  const [lifeIndex, setLifeIndex] = useState(0);
  const [teamAssessment, setTeamAssessment] = useState(
    teamQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: teamOptions,
    }))
  );
  const [teamIndex, setTeamIndex] = useState(0);
  const [roleAssessment, setRoleAssessment] = useState(
    roleQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: roleOptions,
    }))
  );
  const [roleIndex, setRoleIndex] = useState(0);
  const [resilianceAssessment, setResilianceAssessment] = useState(
    resilienceQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: resilienceOptions,
    }))
  );
  const [resIndex, setResIndex] = useState(0);
  const [supportAssessment, setSupportAssessment] = useState(
    supportQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: supportOptions,
    }))
  );
  const [spIndex, setSpIndex] = useState(0);
  const [personalityAssessment, setPersonalityAssessment] = useState(
    personalityQuestions.map((item, index) => ({
      ...item,
      scale: 0,
      answeOptions: [3, 9, 16, 20, 23].includes(index)
        ? personalityOptions2
        : personalityOptions,
    }))
  );
  const [psIndex, setPsIndex] = useState(0);
  const [safeAssessment, setSafeAssessment] = useState(
    safeQuestions.map((item) => ({
      ...item,
      scale: 0,
      answeOptions: [0, 2, 4].includes(0) ? safeOptions2 : safeOptions,
    }))
  );
  const [saIndex, setSaIndex] = useState(0);
  const [digitalAssessment, setDigitalAssessment] = useState(
    digitalQuestions.map((item, index) => ({
      ...item,
      scale: 0,
      answeOptions: digitalOptions,
    }))
  );
  const [diIndex, setDiIndex] = useState(0);
  const [disable, setDisable] = useState(false);

  const topRef = React.createRef();
  const myButtonRef = React.createRef();
  const stepRef = useRef(step); 
  const idRef = useRef(wellbeingId);

  useEffect(() => {
    stepRef.current = step;
    idRef.current = wellbeingId;
  }, [step,wellbeingId]);
  useEffect(()=>{
    if(props?.location?.state?.step){
      setShowResult(44)
    }
  },[props])
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      submitData();
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [prevResponse,
    extraQuestions,
    burnOut1,burnOut1,burnOut3,stressAssessment,eqAssessment,workAssessment,lifeAssessment,
    teamAssessment,roleAssessment,resilianceAssessment,supportAssessment,personalityAssessment,
    safeAssessment,digitalAssessment,
  ]);

  useEffect(() => {
    setShowLoader(true);
    if(!localStorage?._patientKey){
      window.location.replace(LOGIN_URL)
    }
    const checkIncompleteAssessment = async () => {
     const resp = await postReq(`/business-wellbeing/get-draft/`,{patient_n_key:localStorage?._patientKey});
     setShowLoader(false)
     console.log(resp)
     if(resp?.data?.data?.status === 'Draft'){
      setStep(resp?.data?.data?.current_step?parseInt(resp?.data?.data?.current_step):1);
      setWellbeingId(resp?.data?.data?.bu_well_id);
      setOpen(true);
      setPrevResponse(resp.data.data);
     }
    };
    checkIncompleteAssessment();
  }, []);

  const sumScale = (arr) => {
    try {
      if (!Array.isArray(arr)) {
        throw new Error("Input is not an array");
      }
      return arr
        .map((item) => (item && Number(item.scale)) || 0)
        .reduce((acc, val) => acc + val, 0);
    } catch (error) {
      console.error("Error in sumScale:", error.message);
      return 0;
    }
  };
  
  const postFirstStep=async()=>{
    const resp = await postReq(`/business-wellbeing/`,{patient_n_key:localStorage._patientKey});
    console.log(resp)
    if(resp.data.status==='Inprogress'){
      setWellbeingId(resp?.data?.bu_well_id)
    }
  }
  const refactorData=()=>{
    let SB_Result = "";
    let EI_Result = "";
    let WSE_Result = "";
    let CMR_Result = "";
    let WLB_Result = "";
    let IDTC_Result = "";
    let RCF_Result = "";
    let POS_Result = "";
    let PBT_Result = "";
    let PS_Result = "";
    let DEI_Result = "";
    let DTS_Result = "";
    let SB_Status = "";
    let EI_Status = "";
    let WSE_Status = "";
    let CMR_Status = "";
    let WLB_Status = "";
    let IDTC_Status = "";
    let RCF_Status = "";
    let POS_Status = "";
    let PBT_Status = "";
    let PS_Status = "";
    let DEI_Status = "";
    let DTS_Status = "";
    const positive_areas = [];
    const focus_areas = [];
    const EE = sumScale(burnOut1);
    const DP = sumScale(burnOut2);
    const PA = sumScale(burnOut3);
    const totalBurnout = EE + DP + (48 - PA);
    if (totalBurnout >= 0 && totalBurnout <= 25) {
      SB_Result = "THRIVING";
      SB_Status = "Excellent";
      focus_areas.push("stress_and_burnout_levels");
    } else if (totalBurnout >= 26 && totalBurnout <= 50) {
      SB_Result = "MANAGING";
      SB_Status = "Excellent";
      focus_areas.push("stress_and_burnout_levels");
    } else if (totalBurnout >= 51 && totalBurnout <= 75) {
      SB_Result = "STRUGGLING";
      SB_Status = "Moderate";
      positive_areas.push("stress_and_burnout_levels");
    } else if (totalBurnout >= 76) {
      SB_Result = "SOS MODE";
      SB_Status = "Low";
      positive_areas.push("stress_and_burnout_levels");
    }
    const stressScore = sumScale(stressAssessment);
    if (stressScore >= 0 && stressScore <= 13) {
      EI_Result = "LOW STRESS ZONE";
      EI_Status = "Excellent";
      focus_areas.push("emotional_intelligence");
    } else if (stressScore >= 14 && stressScore <= 26) {
      EI_Result = "MEDIUM STRESS ZONE";
      EI_Status = "Moderate";
      focus_areas.push("emotional_intelligence");
    } else if (stressScore >= 27 && stressScore <= 40) {
      EI_Result = "HIGH STRESS ZONE";
      EI_Status = "Low";
      positive_areas.push("emotional_intelligence");
    }
    const eqScore = sumScale(eqAssessment);
    if (eqScore >= 0 && eqScore <= 32) {
      WSE_Result = "EMERGING EQ";
      WSE_Status = "Low";
      focus_areas.push("workplace_satisfaction_and_engagement");
    } else if (eqScore >= 33 && eqScore <= 82) {
      WSE_Result = "DEVELOPING EQ";
      WSE_Status = "Low";
      focus_areas.push("workplace_satisfaction_and_engagement");
    } else if (eqScore >= 83 && eqScore <= 132) {
      WSE_Result = "BALANCED EQ";
      WSE_Status = "Moderate";
      positive_areas.push("workplace_satisfaction_and_engagement");
    } else if (eqScore >= 133 && eqScore <= 165) {
      WSE_Result = "ADVANCED EQ";
      WSE_Status = "Excellent";
      positive_areas.push("workplace_satisfaction_and_engagement");
    }
    const workScore = sumScale(workAssessment);
    if (workScore >= 0 && workScore <= 35) {
      CMR_Result = "SEEKING CHANGE";
      CMR_Status = "Low";
      focus_areas.push("coping_mechanisms_and_resilience");
    } else if (workScore >= 36 && workScore <= 71) {
      CMR_Result = "STABLE BUT GROWING";
      CMR_Status = "Low";
      focus_areas.push("coping_mechanisms_and_resilience");
    } else if (workScore > 72 && workScore <= 144) {
      CMR_Result = "THRIVING AT WORK";
      CMR_Status = "Moderate";
      positive_areas.push("coping_mechanisms_and_resilience");
    } else if (workScore > 144 && workScore <= 216) {
      CMR_Result = "EXCELLING AT WORK";
      CMR_Status = "Excellent";
      positive_areas.push("coping_mechanisms_and_resilience");
    }
    const workresilianceScore = sumScale(resilianceAssessment);
    if (workresilianceScore >= 0 && workresilianceScore <= 50) {
      WLB_Result = "BUILDING RESILIENCE";
      WLB_Status = "Low";
      focus_areas.push("workLife_balance");
    } else if (workresilianceScore >= 51 && workresilianceScore <= 75) {
      WLB_Result = "RESILIENCE RISING";
      WLB_Status = "Moderate";
      focus_areas.push("workLife_balance");
    } else if (workresilianceScore >= 76 && workresilianceScore <= 100) {
      WLB_Result = "RESILIENCE MASTER";
      WLB_Status = "Excellent";
      positive_areas.push("workLife_balance");
    }
    const lifeScore = sumScale(lifeAssessment);
    if (lifeScore >= 0 && lifeScore <= 18) {
      IDTC_Result = "REBUILDING FOUNDATIONS";
      IDTC_Status = "Low";
      focus_areas.push("interpersonal_dynamics_and_team_compatibility");
    } else if (lifeScore >= 19 && lifeScore <= 44) {
      IDTC_Result = "SEEKING BALANCE";
      IDTC_Status = "Low";
      focus_areas.push("interpersonal_dynamics_and_team_compatibility");
    } else if (lifeScore >= 45 && lifeScore <= 70) {
      IDTC_Result = "FINDING YOUR FLOW";
      IDTC_Status = "Moderate";
      positive_areas.push("interpersonal_dynamics_and_team_compatibility");
    } else if (lifeScore >= 71 && lifeScore <= 95) {
      IDTC_Result = "BALANCE MASTER";
      IDTC_Status = "Excellent";
      positive_areas.push("interpersonal_dynamics_and_team_compatibility");
    }
    const teamScore = sumScale(teamAssessment);
    if (teamScore >= 0 && teamScore <= 23) {
      RCF_Result = "TEAM FOUNDATIONS";
      RCF_Status = "Low";
      focus_areas.push("role_clarity_and_fit");
    } else if (teamScore >= 24 && teamScore <= 56) {
      RCF_Result = "TEAM BUILDING";
      RCF_Status = "Low";
      focus_areas.push("role_clarity_and_fit");
    } else if (teamScore >= 57 && teamScore <= 89) {
      RCF_Result = "TEAM SYNERGY";
      RCF_Status = "Moderate";
      positive_areas.push("role_clarity_and_fit");
    } else if (teamScore >= 90 && teamScore <= 120) {
      RCF_Result = "TEAM EXCELLENCE";
      RCF_Status = "Excellent";
      positive_areas.push("role_clarity_and_fit");
    }
    const roleScore = sumScale(roleAssessment);
    if (roleScore >= 0 && roleScore <= 13) {
      POS_Result = "ROLE FOUNDATIONS";
      POS_Status = "Excellent";
      focus_areas.push("perceived_organizational_support");
    } else if (roleScore >= 14 && roleScore <= 42) {
      POS_Result = "ROLE MASTERY";
      POS_Status = "Excellent";
      focus_areas.push("perceived_organizational_support");
    } else if (roleScore >= 43 && roleScore <= 70) {
      POS_Result = "ROLE NAVIGATION";
      POS_Status = "Moderate";
      positive_areas.push("perceived_organizational_support");
    } else if (roleScore >= 71 && roleScore <= 98) {
      POS_Result = "ROLE RESET NEEDED";
      POS_Status = "Low";
      positive_areas.push("perceived_organizational_support");
    }
    const supportScore = sumScale(supportAssessment);
    if (supportScore >= 0 && supportScore <= 32) {
      PBT_Result = "SUPPORT BOOST NEEDED";
      PBT_Status = "Low";
      focus_areas.push("personality_and_behavioral_traits");
    } else if (supportScore >= 33 && supportScore <= 64) {
      PBT_Result = "MIXED SUPPORT";
      PBT_Status = "Moderate";
      focus_areas.push("personality_and_behavioral_traits");
    } else if (supportScore >= 65 && supportScore <= 96) {
      PBT_Result = "STRONG SUPPORT";
      PBT_Status = "Excellent";
      positive_areas.push("personality_and_behavioral_traits");
    }
    const psScore = sumScale(personalityAssessment);
    if (psScore >= 0 && psScore <= 23) {
      PS_Result = "SEEKING BALANCE";
      PS_Status = "Low";
      focus_areas.push("psychological_safety");
    } else if (psScore >= 24 && psScore <= 56) {
      PS_Result = "FINIDING YOUR FLOW";
      PS_Status = "Moderate";
      focus_areas.push("psychological_safety");
    } else if (psScore >= 57 && psScore <= 89) {
      PS_Result = "BALANCE MASTER";
      PS_Status = "Excellent";
      positive_areas.push("psychological_safety");
    }

    const safeScore = sumScale(safeAssessment);
    if (safeScore >= 0 && safeScore <= 11) {
      DEI_Result = "SAFETY INITIATING";
      DEI_Status = "Low";
      focus_areas.push("diversity_equity_and_inclusion_perception");
    } else if (safeScore >= 12 && safeScore <= 28) {
      DEI_Result = "SAFETY BUILDING";
      DEI_Status = "Low";
      focus_areas.push("diversity_equity_and_inclusion_perception");
    } else if (safeScore >= 29 && safeScore <= 44) {
      DEI_Result = "SAFETY GROWING";
      DEI_Status = "Moderate";
      positive_areas.push("diversity_equity_and_inclusion_perception");
    } else if (safeScore >= 45 && safeScore <= 60) {
      DEI_Result = "SAFETY THRIVING";
      DEI_Status = "Excellent";
      positive_areas.push("diversity_equity_and_inclusion_perception");
    }

    const digiScore = sumScale(digitalAssessment);
    if (digiScore >= 0 && digiScore <= 14) {
      DTS_Result = "TECH MINDFULNESS";
      DTS_Status = "Excellent";
      focus_areas.push("digital_and_technological_stress");
    } else if (digiScore >= 15 && digiScore <= 35) {
      DTS_Result = "TECH-LIFE BALANCE";
      DTS_Status = "Moderate";
      focus_areas.push("digital_and_technological_stress");
    } else if (digiScore >= 36 && digiScore <= 55) {
      DTS_Result = "TECH TENSION";
      DTS_Status = "Low";
      positive_areas.push("digital_and_technological_stress");
    } else if (digiScore >= 56 && digiScore <= 75) {
      DTS_Result = "TECH OVERLOAD";
      DTS_Status = "Low";
      positive_areas.push("digital_and_technological_stress");
    }
    const data = {
      digital_and_technological_stress: {
        assessment_score: digiScore,
        assesment: digitalAssessment,
        total_score: 75,
        progress_status: DTS_Status,
        status: DTS_Result,
        additionalQuestions: extraQuestions?.digitalAssessment,
      },
      diversity_equity_and_inclusion_perception: {
        assessment_score: safeScore,
        assesment: safeAssessment,
        total_score: 60,
        progress_status: DEI_Status,
        status: DEI_Result,
        additionalQuestions: extraQuestions?.safeAssessment,
      },
      psychological_safety: {
        assessment_score: psScore,
        assesment: personalityAssessment,
        total_score: 89,
        progress_status: PS_Status,
        status: PS_Result,
        additionalQuestions: extraQuestions?.personalityAssessment,
      },
      personality_and_behavioral_traits: {
        assessment_score: supportScore,
        assesment: supportAssessment,
        total_score: 96,
        progress_status: PBT_Status,
        status: PBT_Result,
        additionalQuestions: extraQuestions?.supportAssessment,
      },
      perceived_organizational_support: {
        assessment_score: roleScore,
        assesment: roleAssessment,
        total_score: 98,
        progress_status: POS_Status,
        status: POS_Result,
        additionalQuestions: extraQuestions?.roleAssessment,
      },
      role_clarity_and_fit: {
        assessment_score: teamScore,
        assesment: teamAssessment,
        total_score: 120,
        progress_status: RCF_Status,
        status: RCF_Result,
        additionalQuestions: extraQuestions?.teamAssessment,
      },
      interpersonal_dynamics_and_team_compatibility: {
        assessment_score: lifeScore,
        assesment: lifeAssessment,
        total_score: 95,
        progress_status: IDTC_Status,
        status: IDTC_Result,
        additionalQuestions: extraQuestions?.lifeAssessment,
      },
      workLife_balance: {
        assessment_score: workresilianceScore,
        assesment: resilianceAssessment,
        total_score: 100,
        progress_status: WLB_Status,
        status: WLB_Result,
        additionalQuestions: extraQuestions?.resilianceAssessment,
      },
      coping_mechanisms_and_resilience: {
        assessment_score: workScore,
        assesment: workAssessment,
        total_score: 216,
        progress_status: CMR_Status,
        status: CMR_Result,
        additionalQuestions: extraQuestions?.workAssessment,
      },
      workplace_satisfaction_and_engagement: {
        assessment_score: eqScore,
        assesment: eqAssessment,
        total_score: 165,
        progress_status: WSE_Status,
        status: WSE_Result,
        additionalQuestions: extraQuestions?.eqAssessment,
      },
      emotional_intelligence: {
        assessment_score: stressScore,
        assesment: stressAssessment,
        total_score: 40,
        progress_status: EI_Status,
        status: EI_Result,
        additionalQuestions: extraQuestions?.stressAssessment,
      },
      stress_and_burnout_levels: {
        assessment_score: totalBurnout,
        burnOut1:burnOut1,
        burnOut2:burnOut2,
        burnOut3:burnOut3,
        assesment: [ ...burnOut1, ...burnOut2, ...burnOut3 ],
        total_score: 76,
        progress_status: SB_Status,
        status: SB_Result,
        additionalQuestions: {
          burnOut1: extraQuestions?.burnOut1,
          burnOut2: extraQuestions?.burnOut2,
          burnOut3: extraQuestions?.burnOut3,
        },
      },
      focus_areas,
      positive_areas,
      status:step+1===44?'Completed':'Draft',
      patient_n_key: localStorage._patientKey,
      current_step:stepRef.current.toString(),
    };
    return data;
  }
  const handleForward = () => {
    if(step===1){
      postFirstStep()
    }
    setStep((prevStep) => {
      const newStep = prevStep + 1;
      if (
        [
          2, 4, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19, 20, 22, 23, 25, 26, 28, 29,
          31, 32, 34, 35, 37, 38, 40, 41, 43,
        ].includes(newStep)
      ) {
        setDisable(false);
      } else if (newStep === 44) {
        submitData();
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
          setShowResultModal(true);
        }, 5000);
      } else {
        setDisable(true);
      }
      return newStep;
    });
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const submitData = async () => {
    setShowLoader(true)
    const data = refactorData();
    console.log(data)
   const resp =  await patchReq(`/business-wellbeing/${idRef.current}/`, data);
   console.log(resp)
   if(resp?.data?.status){
    setShowLoader(false)
   }
    console.log(resp)
  };

  const handleBurnOutSet = (
    inx,
    index,
    state,
    setState,
    indxState,
    setIndxState
  ) => {
    const updatedState = [...state];
    updatedState[index].answer = updatedState[index].answeOptions[inx].value;
    updatedState[index].scale = updatedState[index].answeOptions[inx].scale;
    updatedState[index].answeOptions = updatedState[index].answeOptions.map(
      (option, idx) => ({
        ...option,
        selected: idx === inx,
      })
    );

    setState((prevState) => {
      const newState = [...prevState];
      newState[index] = updatedState[index];
      handleNext(indxState, setIndxState, state);
      if (indxState + 1 === state.length) {
        setDisable(false);
        myButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        setDisable(true);
      }

      return newState;
    });
  };

  // Usage for burnOut1
  const handleBurnOut1 = (inx, index) =>
    handleBurnOutSet(inx, index, burnOut1, setBurnOut1, indx1, setIndx1);

  // Usage for burnOut2
  const handleBurnOut2 = (inx, index) =>
    handleBurnOutSet(inx, index, burnOut2, setBurnOut2, indx2, setIndx2);

  // Usage for burnOut3
  const handleBurnOut3 = (inx, index) =>
    handleBurnOutSet(inx, index, burnOut3, setBurnOut3, indx3, setIndx3);

  const handlePrev = (index, setIndex) => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNext = (index, setIndex, state) => {
    if (index < state.length - 1) {
      setIndex(index + 1);
    }
  };

  // Usage for burnOut1
  const handlePrev1 = () => handlePrev(indx1, setIndx1);
  const handleNext1 = () => handleNext(indx1, setIndx1, burnOut1);

  // Usage for burnOut2
  const handlePrev2 = () => handlePrev(indx2, setIndx2);
  const handleNext2 = () => handleNext(indx2, setIndx2, burnOut2);

  // Usage for burnOut3
  const handlePrev3 = () => handlePrev(indx3, setIndx3);
  const handleNext3 = () => handleNext(indx3, setIndx3, burnOut3);

  const handleAnswerChange = (assessmentKey, questionIndex, e) => {
    setExtraQuestions((prev) => ({
      ...prev,
      [assessmentKey]: prev[assessmentKey].map((item, index) =>
        index === questionIndex
          ? {
              ...item,
              [questionIndex === 1 ? "answer1" : "answer"]: e.target.value,
            }
          : item
      ),
    }));
  };

const onContinue=()=>{
setStep(step);
setOpen(false);
setDigitalAssessment(prevResponse.digital_and_technological_stress?.assesment);
setSafeAssessment(prevResponse?.diversity_equity_and_inclusion_perception?.assesment);
setPersonalityAssessment(prevResponse?.psychological_safety?.assesment);
setSupportAssessment(prevResponse?.personality_and_behavioral_traits?.assesment);
setRoleAssessment(prevResponse?.perceived_organizational_support?.assesment);
setTeamAssessment(prevResponse?.role_clarity_and_fit?.assesment);
setLifeAssessment(prevResponse?.interpersonal_dynamics_and_team_compatibility?.assesment);
setResilianceAssessment(prevResponse?.workLife_balance?.assesment);
setWorkAssessment(prevResponse?.coping_mechanisms_and_resilience?.assesment);
setEqAssessment(prevResponse?.workplace_satisfaction_and_engagement?.assesment);
setStressAssessment(prevResponse?.emotional_intelligence?.assesment);
setBurnOut1(prevResponse?.stress_and_burnout_levels?.burnOut1);
setBurnOut2(prevResponse?.stress_and_burnout_levels?.burnOut2);
setBurnOut3(prevResponse?.stress_and_burnout_levels?.burnOut3);
};

const onStartNew=()=>{
  setStep(1);
  setOpen(false);
};
  return showLoader ? (
    <Caremeloader />
  ) : showcofetti ? (
    <FullScreenConfetti />
  ) : open ? (
    <Modal centered size="md" show={open} onHide={()=>setOpen(false)} backdrop="static">
  <Modal.Body>
    <h4 className="font-weight-light px-0 py-3 text-center header_font_">
      ⚠️ You’ve Left Your Assessment Incomplete ⚠️
    </h4>
    <p className="font-weight-light text-left h5 text_p_">
      It looks like you previously started an assessment but didn’t complete it. Don’t worry—your progress is saved, and you can pick up right where you left off.
    </p>
    <p className="font-weight-light py-2 h5 text-left text_p_">
      Would you like to continue from where you stopped, or start a new assessment from the beginning?
    </p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={onContinue}>Continue Assessment</Button>
    <Button variant="secondary" onClick={onStartNew}>Start New Assessment</Button>
  </Modal.Footer>
</Modal>

  ) :  showresultmodal ? (
    <>
      <ResultModal
        show={showresultmodal}
        onHide={() => {
          setShowResultModal(false);
          setShowResult(true)
        }}
      />
      <FullScreenConfetti />
    </>
  ) : showresult?<ResultComponent />:(
    <>
      <div
        className={`${
          step !== 44
            ? "container-fluid vh-100 col-md-12 mx-auto d-flex m-0 pt-1 pt-md-3  px-2 px-md-0 px-lg-2 overflow-hidden"
            : ""
        } wellbeing-assessment pb-md-5`}
      >
        {step !== 44 ? <LeftContent step={step + 1} /> : null}
        <div
          ref={topRef}
          className={`${
            step !== 44 ? "col-lg-7 col-md-12  px-md-2  px-2" : ""
          } position-relative vh-100 `}
          style={{ overflowY: "auto", paddingBottom: "45px" }}
        >
          {step == 1 ? <WelcomeScreen /> : null}
          {step == 2 ? (
            <div className="inputcontainer_  mt-md-5 mt-3 ml-md-2 pb-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                Hey You! 👋 Let’s Chat About Your Work-Life Vibe.
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Hustle culture is real, and burnout? Even more real. We get it.
                This is your chance to hit pause, check in with yourself, and
                see where your energy’s at.
              </p>
              <p className="btmtop_ mt-md-2 lead">
                Why bother? Because burnout isn’t just being tired—it’s feeling
                drained, unmotivated, and low-key over it. Taking this quick
                vibe check is your first step to better balance.
              </p>
              <p className="btmtop_ mt-md-2 lead">What’s in it for you? 🚀</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Real talk insights about your work-life balance
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Clarity on what’s zapping your energy
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Actionable tips that actually vibe with your lifestyle
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Access to resources that *actually* help
                </li>
              </ul>
              <p className="btmtop_ mt-md-2 lead">
                Your answers? Totally private. This is your space—be honest, be
                real, and let’s make self-care your superpower. 💪
              </p>
            </div>
          ) : null}
          {step === 3 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-4">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {indx1 + 1}. {burnOut1[indx1].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {burnOut1[indx1].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() => handleBurnOut1(index, indx1)}
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          burnOut1[indx1].answer == i.value ? "bold" : "500",
                        backgroundColor:
                          burnOut1[indx1].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={handlePrev1}
                handleNext={handleNext1}
                array={burnOut1}
                indx={indx1}
              />
            </div>
          ) : null}
          {step == 4 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                When you feel most drained, what helps you recharge? (This helps
                us understand your current coping mechanisms and personal
                preferences for recovery)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("burnOut1", 0, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 5 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ mt-md-5 pt-md-5 h2">
                🧠 Feeling Disconnected from Work? (AKA Depersonalization)
              </p>
              <p className="secondtxt_ lead mt-md-4">
                😶‍🌫️ Ever feel like your job is just a checklist, and you're on
                autopilot? That’s what we call depersonalization—when work
                starts to feel more like a chore and less like something
                meaningful. It’s like you're there, but not really...
                emotionally, you’ve checked out.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🫠 Maybe you’ve caught yourself saying, “Whatever, it’s just a
                job,” or avoiding your coworkers because it feels like too much
                effort. This vibe usually comes with exhaustion, a touch of
                cynicism, and questioning why you're even doing this in the
                first place.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🧐 Maybe it’s time to ask yourself: What part of this job still
                sparks a little joy? Can you find one person in your workplace
                who feels like a safe space? Or is it time to have that
                heart-to-heart with your manager about finding a better fit or
                redefining your role?
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🧘‍♀️ Remember, your job doesn’t define your worth. Prioritize your
                mental health by setting boundaries, taking those PTO days, and
                making time for stuff that actually makes you happy. When you
                feel good outside of work, it’s way easier to find purpose
                within it.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                ✅ <strong>TL;DR:</strong> You're not alone in this feeling, and
                it’s totally okay to hit pause, reassess, and figure out what
                you need to thrive—both in your job and in life. 🌟
              </p>
            </div>
          ) : null}
          {step === 6 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {indx2 + 1}. {burnOut2[indx2].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {burnOut2[indx2].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() => handleBurnOut2(index, indx2)}
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          burnOut2[indx2].answer == i.value ? "bold" : "500",
                        backgroundColor:
                          burnOut2[indx2].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={handlePrev2}
                handleNext={handleNext2}
                array={burnOut2}
                indx={indx2}
              />
            </div>
          ) : null}
          {step == 7 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Can you recall moments where you felt disconnected at work?
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("burnOut2", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What stood out to you during those times?
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("burnOut2", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 8 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ mt-md-5 pt-md-5 h2">
                🎯 Achievement Vibes (Personal Accomplishment)
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🏆 Achievement Vibes are all about that feeling when you
                absolutely crush a goal—big or small. It’s that “Yes, I did it!”
                moment that boosts your confidence, fuels your self-belief, and
                gets you hyped to tackle whatever comes next.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🚀 This energy isn’t just about trophies, certificates, or
                milestones. It’s about the emotional glow-up that comes from
                pushing past your limits, overcoming challenges, and realizing
                just how capable you are.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🌟 Celebrating your wins isn’t bragging—it’s self-care. Taking a
                moment to reflect on how far you’ve come builds gratitude,
                strengthens your mindset, and keeps you grounded as you aim for
                the next big thing.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                🔥 So, here’s your reminder: Celebrate the milestones,
                appreciate the journey, and keep that momentum going. You’ve got
                this, and every step forward counts.
              </p>
              <p className="secondtxt_ lead mt-md-4">
                ✅ <strong>TL;DR:</strong> Achievement vibes are about feeling
                proud, owning your wins, and using that energy to fuel your next
                adventure. Keep shining! 🌟🚀
              </p>
            </div>
          ) : null}
          {step === 9 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {indx3 + 1}. {burnOut3[indx3].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {burnOut3[indx3].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() => handleBurnOut3(index, indx3)}
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          burnOut3[indx3].answer == i.value ? "bold" : "500",
                        backgroundColor:
                          burnOut3[indx3].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={handlePrev3}
                handleNext={handleNext3}
                array={burnOut3}
                indx={indx3}
              />
            </div>
          ) : null}
          {step === 10 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What achievements at work have made you feel the most fulfilled?
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("burnOut3", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Why do you think they stood out?
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("burnOut3", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 11 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                😌 Hey there! Ready for a Quick Stress Reality Check?
              </p>

              <p className="secondtxt_ lead mt-md-2">
                🧠 Life can get *real*—deadlines piling up, relationship drama,
                and that *never-ending* to-do list. We feel you! 👀 Think of
                this quick assessment as your *personal stress GPS*—let’s
                pinpoint where you’re at and how we can help.
              </p>

              <p className="btmtop_ mt-md-2 lead">
                🚀 <strong>What’s cool about this?</strong>
              </p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" /> ⏱️ Takes
                  just *5 minutes*—quick and easy!
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" /> 📊 Gives
                  you *real insights* into your stress levels.
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" /> 🤝 Helps us
                  match you with *personalized support*.
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" /> 🔒 *100%
                  confidential*—your space, your rules.
                </li>
              </ul>

              <p className="secondtxt_ lead mt-md-2">
                🫶 Your responses are totally private and will only be used to
                provide the *best possible support*. Take your time—this is your
                space to be *real, honest,* and unapologetically *YOU*. 🌟
              </p>

              <p className="secondtxt_ lead mt-md-2">
                ✅ *TL;DR:* Quick, confidential, and all about *you.* Let’s team
                up and tackle stress, one insight at a time! 🧘‍♀️💪
              </p>
            </div>
          ) : null}
          {step == 12 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {stressIndex + 1}. {stressAssessment[stressIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {stressAssessment[stressIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          stressIndex,
                          stressAssessment,
                          setStressAssessment,
                          stressIndex,
                          setStressIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          stressAssessment[stressIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          stressAssessment[stressIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(stressIndex, setStressIndex)}
                handleNext={() =>
                  handleNext(stressIndex, setStressIndex, stressAssessment)
                }
                array={stressAssessment}
                indx={stressIndex}
              />
            </div>
          ) : null}
          {step === 13 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                When stress hits hard, what's your go-to way to deal? (This
                helps us understand your current coping toolkit)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("stressAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's the biggest source of stress in your life right now?
                (Helps us tailor support to your specific situation)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("stressAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 14 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🧠✨ Ready to Level Up Your Emotional Intelligence Game?
              </p>
              <p className="secondtxt_ lead mt-md-2">
                You know emotions can get messy, but mastering them? Total game
                changer. This quiz will help you unlock your emotional
                superpowers so you can vibe higher in both life and work. It’s
                time to discover the best version of yourself – let’s go! 💥
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Emotions? Yeah, they can be messy. But mastering them? That’s a
                total power move. This quick EQ check (backed by science, btw
                🧪) will reveal your emotional strengths and the areas where you
                can glow up!
              </p>
              <p className="btmtop_ mt-md-2 lead">Why Should You Care? 👀</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build unbreakable friendships & work bonds 🤝✨
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Boss-level leadership vibes 🚀👑
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Make smarter, stress-free decisions 🧠💡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Keep it real with deeper connections 💖🔥
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to unlock those emotional superpowers? 🦸‍♂️💥 Let's make
                those "aha" moments happen. You’ve got this, and we’ve got your
                back – it’s time to shine brighter than ever! 🌟
              </p>
            </div>
          ) : null}
          {step === 15 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {eqIndex + 1}. {eqAssessment[eqIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {eqAssessment[eqIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          eqIndex,
                          eqAssessment,
                          setEqAssessment,
                          eqIndex,
                          setEqIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          eqAssessment[eqIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          eqAssessment[eqIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(eqIndex, setEqIndex)}
                handleNext={() => handleNext(eqIndex, setEqIndex, eqAssessment)}
                array={eqAssessment}
                indx={eqIndex}
              />
            </div>
          ) : null}
          {step === 16 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about a recent emotional challenge at work. How did you
                handle it, and what did you learn about yourself? (Helps us
                understand your emotional processing and growth){" "}
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("eqAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's your biggest emotional strength, and how does it show up
                in your work life? (Reveals self-awareness and application)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("eqAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 17 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🚀 Yo, Let’s Talk About Your Work Life! 💼
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Ever wonder if you’re really vibing with your job? This quick
                check (backed by the Job Satisfaction Survey, which is legit 🔍)
                helps you figure out what’s popping and what’s kinda holding you
                back in your work life. Think of it like your career GPS to find
                your true north! 🎯✨
              </p>
              <p className="btmtop_ mt-md-2 lead">
                Why This Hits Different: 👀
              </p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Know what *actually* drives you at work ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot what needs to level up 🔄🔥
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Get matched with the right support squad 🤝
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Make your work life work for *you* 🌟💯
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to turn your work life into something that makes you feel
                alive? Let’s dive in and start making some magic happen ✨🔥.
                You’ve got this, now let’s make it happen together!
              </p>
            </div>
          ) : null}
          {step === 18 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {workIndex + 1}. {workAssessment[workIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {workAssessment[workIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          workIndex,
                          workAssessment,
                          setWorkAssessment,
                          workIndex,
                          setWorkIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          workAssessment[workIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          workAssessment[workIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(workIndex, setWorkIndex)}
                handleNext={() =>
                  handleNext(workIndex, setWorkIndex, workAssessment)
                }
                array={workAssessment}
                indx={workIndex}
              />
            </div>
          ) : null}
          {step === 19 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's one thing that would make your work life significantly
                better right now? (Helps us understand your priorities and pain
                points)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("workAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about your best day at work recently - what made it great?
                (Reveals key satisfaction drivers){" "}
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("workAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 20 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🚀 Ready to Unleash Your Bounce-Back Superpower? 💪
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Life throws curveballs, no cap. But how you deal with them?
                That’s where your resilience comes in! This quick assessment
                (based on the legit Connor-Davidson Resilience Scale) helps you
                figure out your natural bounce-back abilities and where you can
                level up. Think of it like your personal strength meter! ⚡
              </p>
              <p className="btmtop_ mt-md-4 lead">Why You Need This: 🔥</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Know your resilience superpowers 🦸‍♂️⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot where you can level up 📈✨
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Get support that actually hits 🎯
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build your emotional armor for whatever life throws at you
                  🛡️💥
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                You’ve got the power to bounce back from anything. Time to tap
                into that strength and level up your resilience. Let’s go, fam!
                🔥💪
              </p>
            </div>
          ) : null}
          {step === 21 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {resIndex + 1}. {resilianceAssessment[resIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {resilianceAssessment[resIndex].answeOptions?.map(
                  (i, index) => {
                    return (
                      <button
                        key={i.value}
                        onClick={() =>
                          handleBurnOutSet(
                            index,
                            resIndex,
                            resilianceAssessment,
                            setResilianceAssessment,
                            resIndex,
                            setResIndex
                          )
                        }
                        className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                        style={{
                          fontWeight:
                            resilianceAssessment[resIndex].answer == i.value
                              ? "bold"
                              : "500",
                          backgroundColor:
                            resilianceAssessment[resIndex].answer == i.value
                              ? "#ddefe5"
                              : "#fff",
                        }}
                      >
                        {i.value}
                      </button>
                    );
                  }
                )}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(resIndex, setResIndex)}
                handleNext={() =>
                  handleNext(resIndex, setResIndex, resilianceAssessment)
                }
                array={resilianceAssessment}
                indx={resIndex}
              />
            </div>
          ) : null}
          {step === 22 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about your toughest challenge recently - what helped you
                get through it? (Helps us understand your personal coping
                strategies)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) =>
                  handleAnswerChange("resilianceAssessment", 0, e)
                }
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's your go-to source of strength when things get rough?
                (Reveals support systems and resilience resources){" "}
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) =>
                  handleAnswerChange("resilianceAssessment", 1, e)
                }
              ></textarea>
            </div>
          ) : null}
          {step === 23 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🙌 Hey there! Ready to check if your work and life are vibing
                together? ⚡
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Finding the perfect balance between slaying at work and living
                your best life can be tricky. This quick assessment (based on
                the research-backed Hayman Work-Life Balance Scale) helps you
                see where you're at and what might need a little tweaking. Think
                of it as your personal work-life vibe checker! 🎯💫
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 🔥</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot energy drains before they hit hard ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Find where you're crushing it and where you need balance 🌟
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Get personalized tips that actually work 💫
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build a sustainable work-life rhythm that feels just right 🎶
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to find your flow? Let’s get you back on track to living
                your best balanced life! 🌈💼
              </p>
            </div>
          ) : null}
          {step === 24 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {lifeIndex + 1}. {lifeAssessment[lifeIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {lifeAssessment[lifeIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          lifeIndex,
                          lifeAssessment,
                          setLifeAssessment,
                          lifeIndex,
                          setLifeIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          lifeAssessment[lifeIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          lifeAssessment[lifeIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(lifeIndex, setLifeIndex)}
                handleNext={() =>
                  handleNext(lifeIndex, setLifeIndex, lifeAssessment)
                }
                array={lifeAssessment}
                indx={lifeIndex}
              />
            </div>
          ) : null}
          {step === 25 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's your ideal "perfect day" balancing work and personal
                life? (Helps us understand your balance goals)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("lifeAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's the biggest obstacle to achieving better work-life
                balance right now? (Reveals key challenges and needed support)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("lifeAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 26 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🔥 Ready to decode your team's emotional superpower? 💪
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Understanding how your team vibes together is key to crushing it
                at work! This quick assessment (based on solid research in team
                emotional intelligence) helps you understand how you and your
                squad handle emotions, conflicts, and collaboration. Think of it
                as your team's emotional GPS! 🗺️✨
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 🚀</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Better team chemistry ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Smoother conflict resolution 🌊
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Stronger collaboration game 🎯
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Next-level team performance 🚀
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to unlock your team's emotional superpowers? Let's get
                started and take your squad's performance to the next level!
                💥👊
              </p>
            </div>
          ) : null}
          {step === 27 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {teamIndex + 1}. {teamAssessment[teamIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {teamAssessment[teamIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          teamIndex,
                          teamAssessment,
                          setTeamAssessment,
                          teamIndex,
                          setTeamIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          teamAssessment[teamIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          teamAssessment[teamIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(teamIndex, setTeamIndex)}
                handleNext={() =>
                  handleNext(teamIndex, setTeamIndex, teamAssessment)
                }
                array={teamAssessment}
                indx={teamIndex}
              />
            </div>
          ) : null}
          {step === 28 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about your team's best moment recently - what made it
                special? (Helps understand team dynamics and strengths)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("teamAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                If you could improve one thing about how your team works
                together, what would it be? (Reveals growth opportunities and
                team needs){" "}
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("teamAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 29 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🔍 Let's get clear about your role! 💼
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Ever feel like you're playing a game where the rules keep
                changing? Or maybe you're juggling too many different
                expectations? This quick assessment (based on the
                scientifically-proven Rizzo Role Scales) helps you understand
                where you stand with your work role and responsibilities. Think
                of it as your job clarity compass! 🧭✨
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 🚀</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Get clear on your role ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot where expectations get fuzzy 🌫️
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Identify conflicting demands 🔄
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Get the support you need to thrive 🌟
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to cut through the clutter and get a crystal-clear picture
                of your role? Let’s dive in and gain the clarity you need! 💪🎯
              </p>
            </div>
          ) : null}
          {step === 30 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {roleIndex + 1}. {roleAssessment[roleIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {roleAssessment[roleIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          roleIndex,
                          roleAssessment,
                          setRoleAssessment,
                          roleIndex,
                          setRoleIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          roleAssessment[roleIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          roleAssessment[roleIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(roleIndex, setRoleIndex)}
                handleNext={() =>
                  handleNext(roleIndex, setRoleIndex, roleAssessment)
                }
                array={roleAssessment}
                indx={roleIndex}
              />
            </div>
          ) : null}
          {step === 31 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about a time when your role felt really clear and
                satisfying - what made it work? (Helps understand ideal role
                conditions)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("roleAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                When you face conflicting demands, how do you typically handle
                them? (Reveals coping strategies and support needs){" "}
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("roleAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 32 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🔍 Let’s talk about how supported you feel at work! 🤝
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Ever wonder if your company truly has your back? This quick
                assessment (based on the science-backed Survey of Perceived
                Organizational Support) helps you understand how supported you
                really are at work. Think of it as your workplace relationship
                health check! 💫
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 💡</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Understand your work relationships better 🤝
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot where support might be lacking ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Know when and how to ask for what you need 🎯
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build a healthier and more supportive work environment 🌱
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to gain insights and build stronger connections at work?
                Let’s dive in and explore your support system! 🌟
              </p>
            </div>
          ) : null}
          {step === 33 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {spIndex + 1}. {supportAssessment[spIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {supportAssessment[spIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          spIndex,
                          supportAssessment,
                          setSupportAssessment,
                          spIndex,
                          setSpIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          supportAssessment[spIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          supportAssessment[spIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(spIndex, setSpIndex)}
                handleNext={() =>
                  handleNext(spIndex, setSpIndex, supportAssessment)
                }
                array={supportAssessment}
                indx={spIndex}
              />
            </div>
          ) : null}
          {step === 34 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's one thing your organization does that makes you feel most
                supported? (Helps identify effective support mechanisms)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("supportAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                If you could improve one aspect of organizational support, what
                would it be and why? (Reveals support gaps and priorities)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("supportAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 35 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🌟 Ready to discover your unique personality blend? 🌟
              </p>
              <p className="secondtxt_ lead mt-md-2">
                This isn’t just another personality quiz — it’s a
                scientifically-backed tool to help you understand your natural
                strengths and tendencies! Based on the Big Five Personality
                Traits model (the gold standard in personality science), this
                assessment uncovers your unique personality pattern. Think of it
                as your personal character map! 🗺️
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 💡</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Understand your natural strengths 💪
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Know what truly energizes you ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build better, more meaningful relationships 🤝
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Discover your ideal work style and thrive 🎯
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to uncover your true self and take charge of your personal
                growth? Let’s dive into your personality journey! 🚀
              </p>
            </div>
          ) : null}
          {step === 36 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {psIndex + 1}. {personalityAssessment[psIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {personalityAssessment[psIndex].answeOptions?.map(
                  (i, index) => {
                    return (
                      <button
                        key={i.value}
                        onClick={() =>
                          handleBurnOutSet(
                            index,
                            psIndex,
                            personalityAssessment,
                            setPersonalityAssessment,
                            psIndex,
                            setPsIndex
                          )
                        }
                        className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                        style={{
                          fontWeight:
                            personalityAssessment[psIndex].answer == i.value
                              ? "bold"
                              : "500",
                          backgroundColor:
                            personalityAssessment[psIndex].answer == i.value
                              ? "#ddefe5"
                              : "#fff",
                        }}
                      >
                        {i.value}
                      </button>
                    );
                  }
                )}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(psIndex, setPsIndex)}
                handleNext={() =>
                  handleNext(psIndex, setPsIndex, personalityAssessment)
                }
                array={personalityAssessment}
                indx={psIndex}
              />
            </div>
          ) : null}
          {step === 37 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about when you feel most "in your element" - what kind of
                situation is it? (Helps understand optimal environments)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) =>
                  handleAnswerChange("personalityAssessment", 0, e)
                }
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                How do you think your personality traits show up in your work
                style? (Reveals self-awareness and work preferences)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) =>
                  handleAnswerChange("personalityAssessment", 1, e)
                }
              ></textarea>
            </div>
          ) : null}
          {step === 38 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🌟 Hey there! Let’s talk about psychological safety at work! 🌟
              </p>
              <p className="secondtxt_ lead mt-md-2">
                Ever wondered if it’s really okay to be yourself at work? Or if
                speaking up is truly welcomed? This quick assessment, based on
                Dr. Amy Edmondson's groundbreaking research, helps you
                understand how safe you feel being your authentic self with your
                team. Think of it as your workplace trust meter! 🎯
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 💡</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Better team collaboration 🤝
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  More creative ideas flowing ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Less stress, more growth 🌱
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Stronger team connections 💫
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to feel more supported, connected, and confident at work?
                Let’s dive in and explore your workplace safety! 💪
              </p>
            </div>
          ) : null}
          {step === 39 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {saIndex + 1}. {safeAssessment[saIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {safeAssessment[saIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          saIndex,
                          safeAssessment,
                          setSafeAssessment,
                          saIndex,
                          setSaIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          safeAssessment[saIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          safeAssessment[saIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(saIndex, setSaIndex)}
                handleNext={() =>
                  handleNext(saIndex, setSaIndex, safeAssessment)
                }
                array={safeAssessment}
                indx={saIndex}
              />
            </div>
          ) : null}
          {step === 40 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                Think about a time when you felt completely safe to be yourself
                at work - what made that possible? (Helps understand personal
                safety enablers)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("safeAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What would make you feel more comfortable speaking up in your
                team? (Reveals barriers and needs)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("safeAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step === 41 ? (
            <div className="inputcontainer_ mt-md-5 mt-3 ml-md-5">
              <p className="toptext_ h2 py-2 py-md-4 mt-3 mt-md-4">
                🌐 Let’s talk about your relationship with tech! 📱
              </p>
              <p className="secondtxt_ lead mt-md-2">
                In today’s always-on world, technology can be both a superpower
                and a source of stress. This quick assessment, based on
                scientific research in technostress, helps you understand how
                tech is affecting your wellbeing. Think of it as your digital
                wellness compass! 🧭
              </p>
              <p className="btmtop_ mt-md-4 lead">Why this matters: 💡</p>
              <ul className="mt-md-2 lead pl-3">
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Balance your tech life better ⚖️
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Spot digital burnout early ⚡
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Find your tech-life harmony 🎯
                </li>
                <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
                  <Check size={20} color="green" className="mr-2" />
                  Build healthier digital habits 🌱
                </li>
              </ul>
              <p className="secondtxt_ lead mt-md-4">
                Ready to take control of your tech-life balance? Let’s dive into
                how you can thrive in the digital world! 🚀
              </p>
            </div>
          ) : null}
          {step === 42 ? (
            <div className="inputcontainer_ ml-md-5 mt-md-5 mt-3">
              <p className="questiontxt_ user-select-none text_color h3  mt-md-3 pt-md-2-md-3 pt-md-2">
                {diIndex + 1}. {digitalAssessment[diIndex].question}
              </p>
              <div className="radiobox_ mt-md-3 col-md-7 pl-0">
                {digitalAssessment[diIndex].answeOptions?.map((i, index) => {
                  return (
                    <button
                      key={i.value}
                      onClick={() =>
                        handleBurnOutSet(
                          index,
                          diIndex,
                          digitalAssessment,
                          setDigitalAssessment,
                          diIndex,
                          setDiIndex
                        )
                      }
                      className="checkboxtextbtn_ text-left rounded m-2 py-2 px-3 lead text_color_"
                      style={{
                        fontWeight:
                          digitalAssessment[diIndex].answer == i.value
                            ? "bold"
                            : "500",
                        backgroundColor:
                          digitalAssessment[diIndex].answer == i.value
                            ? "#ddefe5"
                            : "#fff",
                      }}
                    >
                      {i.value}
                    </button>
                  );
                })}
              </div>
              <QuestionPagination
                handlePrev={() => handlePrev(diIndex, setDiIndex)}
                handleNext={() =>
                  handleNext(diIndex, setDiIndex, digitalAssessment)
                }
                array={digitalAssessment}
                indx={diIndex}
              />
            </div>
          ) : null}
          {step === 43 ? (
            <div className="d-flex flex-column pt-3 pt-md-4 pt-lg-5">
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                What's your favorite way to unplug and recharge from tech?
                (Helps understand personal coping strategies)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("digitalAssessment", 0, e)}
              ></textarea>
              <p className="questiontxt_ text_color h3 mt-md-3 mb-2 pt-2 pt-md-3 mb-md-0 mr-auto">
                If you could change one thing about how technology affects your
                work life, what would it be? (Reveals key stressors and needs)
              </p>
              <textarea
                name="additionalInfo"
                id="additionalInfo"
                placeholder="Type your text"
                style={{ verticalAlign: "top", height: "120px" }}
                className="border rounded w-100 slick-vertical pl-2 pt-2 mt-3 mt-md-4"
                onChange={(e) => handleAnswerChange("digitalAssessment", 1, e)}
              ></textarea>
            </div>
          ) : null}
          {step !== 44 && (
            <div
              className="col-lg-12 pt-1 d-flex justify-content-end align-content-end bg-white align-items-end flex-row position-fixed"
              style={{ right: 0, bottom: 10 }}
            >
              {step === 4 ||
              step === 7 ||
              step === 10 ||
              step === 13 ||
              step === 16 ||
              step === 19 ||
              step === 22 ||
              step === 25 ||
              step === 28 ||
              step === 31 ||
              step === 34 ||
              step === 37 ||
              step === 40 ||
              step === 43 ? (
                <button
                  ref={myButtonRef}
                  onClick={() => handleForward()}
                  className="btmbtn_ mt-md-3 mb-md-2 d-flex user-select-none mr-2 justify-content-center align-items-center px-4 py-2 font-weight-bold text_p_ rounded "
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#215352",
                  }}
                >
                  Skip
                  <BsSkipForward size={26} className="ml-2" />
                </button>
              ) : null}
              <button
                ref={myButtonRef}
                disabled={step === 1 ? false : disable}
                onClick={() => handleForward()}
                className=" btmbtn_ mt-md-3 mb-md-2 d-flex user-select-none justify-content-center align-items-center px-4 py-2 font-weight-bold text_p_ rounded "
                style={{
                  opacity: step === 1 ? 1 : disable ? 0.5 : 1,
                  fontSize: "16px",
                  backgroundColor:
                    step === 1 ? "#215352" : disable ? "#333333" : "#215352",
                }}
              >
                {step == 1 ? `Let's get started!` : "Continue"}
                <ArrowRight size={26} className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WellbeingHome;
